/*eslint-disable */

import {
  bus
} from '@/main';
import videoSocketRef from "@/services/videoNotificationSocket/";

var callAutoDis;
const $ = window.jQuery = require('jquery');
export default {
  name: 'video-call-notification-popup',
  components: {},
  props: ['callerNAME', 'userIDforVideoCall', 'videoCallerUserID'],
  data () {
    return {
      roomIdForVideoCall:'',
      interClearVal:false,
      selfUserId:''
    }
  },
  computed: {

  },
  mounted () {
    if(localStorage.getItem('LoginedUserID')) {
      this.selfUserId = localStorage.getItem('LoginedUserID')
    }
    callAutoDis = setInterval(() => {
      this.callNotAnsweredFun()
      this.interClearVal = true
      if(this.interClearVal == true){
        clearInterval(callAutoDis)
      }
    }, 30000);
  },
  methods: {
    callAcceptFunction(){
      this.$store.dispatch('generateChatRoomIdMethod', {user:this.userIDforVideoCall}).then((res)=>{
        this.roomIdForVideoCall = res.data.data.id
        videoSocketRef.emit({
          message: `Call Accepted.`,
          type: "videoCallAccepted",
          returnCallerUserId:this.videoCallerUserID,
          videoCallRoomId:this.roomIdForVideoCall,
          targetId:this.userIDforVideoCall,
          user:this.selfUserId,
          second_user_id:this.videoCallerUserID
        });
        videoSocketRef.emit({
          message: `Other Tabs Caller Popup Closed.`,
          type: "videoAlertPopupClose",
          videoCallRoomId:this.userIDforVideoCall,
          second_user_id:this.videoCallerUserID
        });
        bus.$emit('callerNotifiactionBus', false);
        this.$router.push('/video/conference/#' + this.roomIdForVideoCall);
        clearInterval(callAutoDis)
        $('body').removeClass('videoNotificationPopup')
      }).catch((err)=>{
      })
    },
    callDeclineFun(){
      videoSocketRef.emit({
        message: `Call Declined.`,
        type: "videoCallDecline",
        videoCallDeclinedId:this.userIDforVideoCall,
        targetId:this.userIDforVideoCall,
        callerID:this.videoCallerUserID,
        second_user_id:this.videoCallerUserID
      });
      videoSocketRef.emit({
        message: `Other Tabs Caller Popup Closed.`,
        type: "videoAlertPopupClose",
        videoCallRoomId:this.userIDforVideoCall,
        second_user_id:this.videoCallerUserID
      });
      clearInterval(callAutoDis)
      bus.$emit('callerNotifiactionBus', false);
      $('body').removeClass('videoNotificationPopup')
    },
    callNotAnsweredFun(){
      videoSocketRef.emit({
        message: `Call Not recieved.`,
        type: "videoCallNotrecieved",
        videoCallDeclinedId:this.userIDforVideoCall,
        targetId:this.userIDforVideoCall,
        callerID:this.videoCallerUserID,
        second_user_id:this.videoCallerUserID,
        'chat_data': {
          'message': 'Missed Call'
        }
      });
      videoSocketRef.emit({
        message: `Other Tabs Caller Popup Closed.`,
        type: "videoAlertPopupClose",
        notRecivedCallerId:this.userIDforVideoCall,
        second_user_id:this.videoCallerUserID
      });
      bus.$emit('callerNotifiactionBus', false);
      $('body').removeClass('videoNotificationPopup')
    }
  }
}